.About {
    margin-top: 12px;
    margin-bottom: 20px;
    padding-bottom: 0;
    text-align: left;
    /*NOTE MARGINS ARE handled.. 1. in app-container disableGutters. 2. set in nav.js Box sx = paddingleft: * ;*/
    /*padding-right: 40px;*/

}

.h3 {
    color: #86b95a;
    font-size: 1.25em;
}

.About-title {

}

.About-desc {
    margin-bottom: 30px;
}