.App {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: grey;
  text-align: center;
  background-color: #e4f2ff;


}


.App-logo {
  /*height: 40vmin;*/
  /*pointer-events: none;*/
  /*max-width: 150px;*/
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #E4F0F9;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
