.contact {
    margin-top: 10px;
    text-align: left;
    margin-bottom: 20px;
    /*NOTE MARGINS ARE handled.. 1. in app-container disableGutters. 2. set in nav.js Box sx = paddingleft: * ;*/
    /*padding-right: 40px;*/

}

.chat {
    min-width: 272px;
    height: 420px;
}
